import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

const Checkbox = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 16px;

  svg {
    height: 20px;
    width: 20px;
    fill: ${props => (props.fixed ? '#c6dfe7' : '#335f7d')};
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.fonts.font};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${props => (props.fixed ? '#c6dfe7' : '#335f7d')};
  cursor: pointer;
`;

const Input = styled.input``;

const ConsentTerms = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const Consent = ({ fixed }) => {
  const {
    state: { lang },
    dispatch,
  } = useContext(MainContext);

  const [isChecked, setIsChecked] = useState(false);

  return (
    <Wrapper>
      <Input
        type="checkbox"
        id="agree"
        hidden
        onChange={e => {
          dispatch({ type: 'agree', payload: e.target.checked });
          setIsChecked(e.target.checked);
        }}
      />

      <Label htmlFor="agree" fixed={fixed}>
        <Checkbox fixed={fixed}>
          {!isChecked ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 0H24V24H0V0ZM22.67 22.67V1.33H1.33V22.67H22.67Z"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.619 17.998C9.739 18.096 9.888 18.149 10.041 18.149C10.071 18.149 10.102 18.147 10.133 18.143C10.317 18.117 10.482 18.016 10.588 17.864L19.595 4.895L18.503 4.136L9.906 16.513L5.47 12.875L4.626 13.903L9.619 17.998Z" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 0H0V24H24V0ZM22.67 1.33V22.67H1.33V1.33H22.67Z"
              />
            </svg>
          )}
        </Checkbox>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: Locales[lang].Form.FeedBack.consent,
            }}
          />{' '}
          <ConsentTerms
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              dispatch({ type: 'showTerms', payload: true });
            }}
            dangerouslySetInnerHTML={{
              __html: Locales[lang].Form.FeedBack.consentTerms,
            }}
          />
        </div>
      </Label>
    </Wrapper>
  );
};
export default Consent;
