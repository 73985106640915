import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  top: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 5px;
  svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
    &:hover {
      fill: ${props => props.theme.secondary_blue_dark};
    }
  }
`;
const QuestionButton = () => (
  <Wrapper>
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="#80B0C8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.2118 22C6.60041 22 2.03516 17.514 2.03516 12C2.03516 6.486 6.60041 2 12.2118 2C17.8233 2 22.3885 6.486 22.3885 12C22.3885 17.514 17.8233 22 12.2118 22ZM12.2118 3C7.16217 3 3.05282 7.037 3.05282 12C3.05282 16.963 7.16217 21 12.2118 21C17.2615 21 21.3708 16.963 21.3708 12C21.3708 7.037 17.2615 3 12.2118 3Z" />
      <path d="M12.1426 15.5929C12.5311 15.5929 12.8461 15.9079 12.8461 16.2965C12.8461 16.685 12.5311 17 12.1426 17H12.0274C11.6389 17 11.3239 16.685 11.3239 16.2965C11.3239 15.9079 11.6389 15.5929 12.0274 15.5929H12.1426ZM13.8088 10.611C13.8088 10.1344 13.6689 9.76825 13.3892 9.51242C13.1094 9.25157 12.7227 9.12114 12.229 9.12114C11.7353 9.12114 11.3349 9.23652 11.0277 9.46727C10.726 9.693 10.5751 10.0191 10.5751 10.4454H9.18456L9.15988 10.4003C9.14342 9.67795 9.41769 9.09857 9.9827 8.66215C10.5532 8.22072 11.3074 8 12.2454 8C13.1889 8 13.9267 8.22573 14.4588 8.6772C14.9964 9.12867 15.2652 9.76323 15.2652 10.5809C15.2652 11.1226 15.1061 11.6293 14.788 12.1008C14.4753 12.5673 14.0008 13.084 13.3645 13.6509C13.1067 13.8866 12.9476 14.0973 12.8872 14.2829C12.8269 14.4635 12.7967 14.7745 12.7967 15.216L11.3321 15.2235C11.3376 14.5714 11.3979 14.1199 11.5131 13.8691C11.6338 13.6132 11.9465 13.2772 12.4511 12.8608C12.7693 12.5147 12.9832 12.2789 13.0929 12.1535C13.2027 12.0281 13.3288 11.8651 13.4714 11.6644C13.6196 11.4587 13.7128 11.2832 13.7512 11.1377C13.7896 10.9872 13.8088 10.8116 13.8088 10.611Z" />
    </svg>
  </Wrapper>
);

export default QuestionButton;
