import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import Cookie from 'js-cookie';
import NumberFormat from 'react-number-format';
import jwtDecode from 'jwt-decode';
import isNode from 'detect-node';
import Locales from 'Locales';
import { media } from 'Src/app/Styles/Theme';
import IconCyberSecurity from 'Src/assets/img/ico/icon_cyber_security.svg';
import IconClose from 'Src/assets/img/ico/icon_menu-close_invert.svg';
import LogoTG from 'Src/assets/img/ico/logo_tg.svg';
import MainContext from 'Src/app/MainContextProvider';
import QuestionButton from 'Common/QuestionButton';
import FeedBackForm from 'Common/FeedBackForm';
import useScrollPosition from 'Common/Hooks/useScrollPosition';
import InfoBlock from './InfoBlock';
import CheckCodeButton from './CheckCodeButton';
import {
  StyledGrid,
  LeftCol,
  RightCol,
  UniqueCodeWrapper,
  GifEngineWrapper,
  Input,
  Title,
  ResponseMessage,
} from './StyledComponents';

const Question = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: normal;
  line-height: 36px;
  color: #335f7d;
  img {
    margin-right: 14px;
    display: none;
    ${media.laptop`
      display: block;
  `}
  }
  ${media.mobile`
    align-items: center;
  `}
`;

export const PleaseEnterCode = styled.span`
  position: ${props => props.fixed && ''};
  right: ${props => props.fixed && '100%'};
  bottom: ${props => props.fixed && '0'};
  margin-left: ${props => props.fixed && '0'};
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${props =>
    props.fixed ? props.theme.secondary_blue_light : props.theme.petrol80};
  ${media.desktop`
    margin-left: ${props => props.fixed && '35px'};
  `}
  ${media.ultrawidescreen`
    font-size: 16px;
    line-height: 24px;
  `}
`;

export const Label = styled.label`
  position: absolute;
  right: ${props => props.fixed && 'none'};
  top: ${props => props.fixed && 'none'};
  bottom: ${props => !props.fixed && '2px'};
  bottom: ${props => props.fixed && '0'};
  z-index: 1;
  height: ${props => props.fixed && '42px'};
  width: ${props => (props.fixed ? '260px' : '275px')};
  font-family: ${props => props.theme.fonts.font_head};
  font-size: ${props => (props.fixed ? '29px' : '31px')};
  line-height: ${props => (props.fixed ? '40px' : '56px')};
  letter-spacing: 0.01em;
  color: ${props =>
    props.fixed
      ? props.theme.secondary_blue_light
      : props.theme.secondary_petrol};
  ${media.mobile`
    width: ${props => !props.fixed && '325px'};
    font-size: ${props => (props.fixed ? '29px' : '36px')};
  `}
  ${media.tablet`
    width: ${props => !props.fixed && '392px'};
    font-size: ${props => (props.fixed ? '29px' : '42px')};
  `}
  ${media.desktop`
    bottom: ${props => !props.fixed && '2px'};
    right: ${props => (props.fixed ? '0' : 'none')};
  `}
  ${media.ultrawidescreen`
    width: ${props => !props.fixed && '440px'};
    font-size: ${props => (props.fixed ? '29px' : '49px')};
  `}
  .zero {
    white-space: nowrap;
  }
  .underlines {
    position: absolute;
    width: ${props => (props.fixed ? '257px' : '273px')};
    display: flex;
    justify-content: space-between;
    bottom: 5px;
    flex-wrap: nowrap;
    ${media.mobile`
      width: ${props => !props.fixed && '318px'};
      bottom: ${props => !props.fixed && '2px'};
    `}
    ${media.tablet`
      width: ${props => !props.fixed && '370px'};
    `}
    ${media.ultrawidescreen`
      width: ${props => !props.fixed && '435px'};
      bottom: ${props => !props.fixed && '-5px'};
    `}
    &__line {
      display: block;
      width: ${props => (props.fixed ? '78px' : '84px')};
      height: 1px;
      background: ${props => props.theme.secondary_blue_light};
      background: ${props => props.status === 'n' && props.theme.green};
      background: ${props =>
        props.fixed && props.status === 'n' && props.theme.green_light};
      background: ${props => props.status === 'd' && props.theme.orange_dark};
      background: ${props =>
        props.fixed && props.status === 'd' && props.theme.orange_dark50};
      background: ${props => props.status === 'k' && props.theme.red};
      background: ${props =>
        props.fixed && props.status === 'k' && props.theme.red_light};
      ${media.mobile`
        width: ${props => !props.fixed && '98px'};
      `}
      ${media.tablet`
        width: ${props => !props.fixed && '115px'};
      `}
      ${media.ultrawidescreen`
        width: ${props => !props.fixed && '135px'};
      `}
    }
  }
`;

const TextFindCode = styled.span`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  color: ${props =>
    props.fixed ? props.theme.secondary_petrol : props.theme.petrol80};
`;

const TextWhyAutorization = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${props => props.theme.petrol80};
`;

const EngineImage = styled.img`
  width: 300px;
`;

const CheckCodeBlockWrapper = styled.div`
  width: 100%;
  position: ${props => props.fixed && 'fixed'};
  bottom: ${props => props.fixed && '100%'};
  left: ${props => props.fixed && '0'};
  z-index: ${props => props.fixed && '25'};
  background: ${props => props.fixed && props.theme.primary_petrol};
  border-bottom: 1px solid transparent;
  border-color: ${props => props.fixed && props.theme.secondary_blue_light};
  transition: 200ms ${props => props.fixed && 'ease-out'};
  transition-delay: ${props => (props.fixed ? '100ms' : 0)};
  transform: ${props => props.fixed && 'translate(0, 100%)'};
  margin-bottom: ${props => !props.fixed && '24px'};
`;

const Close = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  background-image: url(${IconClose});
  cursor: pointer;
`;

const CheckCodeBlock = styled.div`
  width: 100%;
  display: ${props => props.fixed && 'flex'};
  flex-direction: column;
  justify-content: ${props => props.fixed && 'space-between'};
  align-items: ${props => props.fixed && 'flex-start'};
  padding: ${props => (props.fixed ? '24px 16px' : '16px')};
  padding: ${props => !props.fixed && '0'};
  margin: ${props => props.fixed && '0 auto'};
  ${media.tablet`
    padding: ${props => (props.fixed ? '30px 16px' : '16px')};
    padding: ${props => !props.fixed && '0'};
    flex-direction: ${props => props.fixed && 'row'};
    align-items: ${props => props.fixed && 'center'};
  `};
  ${media.laptop`
    
    width: ${props => props.fixed && '944px'};
    
  `};
  ${media.desktop`
    width: ${props => props.fixed && '1224px'};
  `};
  ${media.widescreen`
    width: ${props => props.fixed && '1384px'};
  `};
  ${media.ultrawidescreen`
    width: ${props => props.fixed && '1416px'};
  `};
`;

const TextForCallForm = styled.span`
  padding-top: 24px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-bottom: 1px solid;
  cursor: pointer;
`;

const Form = ({ code }) => {
  const form = useRef(null);
  const checkInputRef = useRef(null);
  const checkCodeBock = useRef(null);
  const whyAutorizationRef = useRef(null);
  const findCodeHeaderRef = useRef(null);
  const findCodeRef = useRef(null);
  const rightColRef = useRef(null);

  const {
    state: { auth, token, lang, users },
    dispatch,
  } = useContext(MainContext);

  const [isShownFindCode, setShownFindCode] = useState(false);
  const [isShowWhyAuthorization, setShowWhyAutorization] = useState(false);
  const [isShownFindCodeHeader, setShownFindCodeHeader] = useState(false);
  const [isBlockWhyAuth, setBlockWhyAuth] = useState(false);
  const [isFixedHeader, setFixedHeader] = useState(false);
  const [checkCode, setCheckCode] = useState(code);
  const [formPosition, setFormPosition] = useState(null);
  const [heightForm, setHeightForm] = useState(null);
  const [geo, setGeo] = useState(null);
  const [responseMessage, setResponseMessage] = useState(false);
  const [inspectionId, setInspectionId] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [isShowTextForCallForm, setShowTextForCallForm] = useState(false);
  const [isShowFeedbackForm, setShowFeedbackForm] = useState(false);
  const [isOpenFeedbackForm, setOpenFeedbackForm] = useState(false);
  const [isHideHeader, setHideHeader] = useState(false);
  const [isShowResponseFeedback, setShowResponseFeedback] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isRepeatedRequest, setRepeatedRequest] = useState(false);
  const [currentIP, setCurrentIP] = useState('');
  const [isEnterKeyDown, setEnterKeyDown] = useState(false);

  const {
    FeedBack,
    title,
    question,
    isContrafact,
    enterCode,
    status,
  } = Locales[lang].Form;

  const setGeolocation = geoLocation => {
    const { latitude, longitude } = geoLocation.coords;

    Cookie.set('geo', JSON.stringify({ latitude, longitude }), { expires: 7 }); // 7 days
    setGeo({ latitude, longitude });
  };

  useScrollPosition(() => {
    const isShow = window.pageYOffset >= formPosition + heightForm;

    setHeightForm(form.current.offsetHeight);

    if (isShow !== isFixedHeader && !isShowFeedbackForm) {
      setFixedHeader(isShow);
      setHideHeader(false);
    }
  }, [isFixedHeader, formPosition, isShowFeedbackForm]);

  useEffect(() => {
    const cookieGeo = Cookie.get('geo');
    setFormPosition(form.current.offsetTop);
    if (!isNode) {
      if (cookieGeo) {
        setGeolocation({ coords: JSON.parse(cookieGeo) });
      } else {
        window.navigator.geolocation.getCurrentPosition(setGeolocation);
      }
    }
  }, []);

  useEffect(() => {
    if (token) {
      const { sub, email } = jwtDecode(token);
      dispatch({ type: 'userId', payload: sub });
      dispatch({
        type: 'isShowStatisticsInMenu',
        payload: users.some(item => item.toLowerCase() === email),
      });

      if (!isNode) {
        window.dataLayer_kdx = window.dataLayer_kdx || [];
        window.dataLayer_kdx.push({ userId: sub });
      }

      setBlockWhyAuth(true);
    }
  }, [token]);

  const handleClickOutside = event => {
    if (
      whyAutorizationRef &&
      whyAutorizationRef.current &&
      !whyAutorizationRef.current.contains(event.target)
    ) {
      setShowWhyAutorization(false);
    }

    if (
      findCodeHeaderRef &&
      findCodeHeaderRef.current &&
      !findCodeHeaderRef.current.contains(event.target)
    ) {
      setShownFindCodeHeader(false);
    }
    if (
      findCodeRef &&
      findCodeRef.current &&
      !findCodeRef.current.contains(event.target)
    ) {
      setShownFindCode(false);
    }
  };

  const sendCheckRequest = async () => {
    setLoading(true);
    if (!token) return null;

    const IP =
      currentIP ||
      (await fetch('https://ip.vwgroup.ru/').then(res => res.text()));

    const myHeaders = new Headers();

    myHeaders.append('X-Lang', lang);
    myHeaders.append('Authorization', `Bearer ${token}`);
    myHeaders.append('x-forwarded-for', IP);

    setTimeout(console.log(`%c ${IP}`, 'color: green; font-weight: bold;'), 0);

    const data = {
      method: 'GET',
      headers: myHeaders,
    };

    const refLink = geo
      ? `${process.env.GATSBY_CHECK_CODE_URL}/check/${checkCode}?longitude=${geo.longitude}&latitude=${geo.latitude}`
      : `${process.env.GATSBY_CHECK_CODE_URL}/check/${checkCode}`;
    fetch(refLink, data)
      .then(res => {
        if (res.status === 401) {
          if (!isNode && window.localStorage) {
            window.localStorage.setItem('checkCode', checkCode);
          }
          return null;
        }
        // если код не возвращается
        if (res.status !== 200 && res.status !== 204) {
          // TODO если запрос не возвращается со статусом 200, то отправляем его еще раз, помещая в стейт отметку о повторном запросе, если снова возвращается !== 200, то выводим ошибку
          if (!isRepeatedRequest) {
            setRepeatedRequest(true);
            sendCheckRequest();
            return null;
          }
          setLoading(false);
          setResponseMessage(FeedBack.serverError);
        }
        return res.json();
      })
      .then(res => {
        if (!res) return null;
        if (!res.status) {
          setLoading(false);
          setResponseMessage(res.message ? res.message : FeedBack.serverError);
          setEnterKeyDown(false);
        } else {
          setLoading(false);
          setInspectionId(res.inspectionId);
          setCheckCode(res.code);
          setResponseStatus(res.status);
          setShowTextForCallForm(res.status !== 'n');
          setShowFeedbackForm(false);
          setEnterKeyDown(false);
          if (token) {
            if (!isNode && window.localStorage) {
              window.localStorage.removeItem('checkCode');
            }
          }
        }
      });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setCurrentIP(window.localStorage.getItem('IP'));
  }, []);

  const validate = () =>
    checkCode && checkCode.replace(/\s+/g, '').length >= 15;

  const handleKeyDown = e => {
    if (validate() && !isEnterKeyDown && e.keyCode === 13) {
      setCheckCode(checkCode.replace(/\s+/g, ''));
      setEnterKeyDown(true);
      if (!token) {
        if (!isNode && window.localStorage) {
          window.localStorage.setItem('checkCode', checkCode);
          setShowFeedbackForm(false);
          sendCheckRequest();
          setShowTextForCallForm(true);
        }
        auth.login();
      } else if (!isNode && window.localStorage) {
        window.localStorage.setItem('checkCode', checkCode);
        sendCheckRequest();
      }
    }
  };

  useEffect(() => {
    setCheckCode(code);
  }, [code]);

  return (
    <StyledGrid ref={form} heightForm={heightForm} fixed={isFixedHeader}>
      <UniqueCodeWrapper id="unique-code-wrapper">
        <LeftCol>
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <Question
            ref={findCodeRef}
            onClick={() => setShownFindCode(!isShownFindCode)}
            fixed={isFixedHeader}
          >
            <TextFindCode fixed={isFixedHeader}>
              {isFixedHeader ? question.findCodeForHeader : question.findCode}
            </TextFindCode>
            <QuestionButton
              onClick={() => setShownFindCode(!isShownFindCode)}
            />
            {isShownFindCode && (
              <InfoBlock type="findCode" fixed={isFixedHeader} />
            )}
          </Question>
        </LeftCol>
        <RightCol key="checkCodeBlockCol" ref={rightColRef}>
          <CheckCodeBlockWrapper fixed={isFixedHeader} hidden={isHideHeader}>
            <CheckCodeBlock ref={checkCodeBock} fixed={isFixedHeader}>
              {isFixedHeader && <Close onClick={() => setHideHeader(true)} />}
              {isFixedHeader && (
                <Question
                  ref={findCodeHeaderRef}
                  onClick={() => setShownFindCodeHeader(!isShownFindCodeHeader)}
                  fixed={isFixedHeader}
                >
                  {isFixedHeader && (
                    <img src={IconCyberSecurity} alt="icon_security" />
                  )}
                  <TextFindCode fixed={isFixedHeader} lang={lang}>
                    {isFixedHeader
                      ? question.findCodeForHeader
                      : question.findCode}
                  </TextFindCode>
                  <QuestionButton
                    onClick={() =>
                      setShownFindCodeHeader(!isShownFindCodeHeader)
                    }
                  />
                  {isShownFindCodeHeader && (
                    <InfoBlock type="findCode" fixed={isFixedHeader} />
                  )}
                </Question>
              )}

              <Input fixed={isFixedHeader} status={responseStatus}>
                <PleaseEnterCode fixed={isFixedHeader}>
                  {enterCode}
                </PleaseEnterCode>
                <NumberFormat
                  id="uniqueCode"
                  ref={checkInputRef}
                  value={checkCode}
                  onValueChange={({ formattedValue }) => {
                    setCheckCode(formattedValue.replace(/\s+/g, ''));
                    setResponseStatus(null);
                    setResponseMessage(false);
                    setShowResponseFeedback(false);
                  }}
                  format="##### ##### #####"
                  mask=""
                  autoComplete="off"
                  onFocus={() => {
                    if (responseStatus === 'n') {
                      setCheckCode(null);
                      checkInputRef.current.value = null;
                      setResponseStatus(null);
                    }
                  }}
                  onKeyDown={e => handleKeyDown(e)}
                  required
                />
                <Label
                  htmlFor="uniqueCode"
                  fixed={isFixedHeader}
                  status={responseStatus}
                >
                  <span className="zero">00000 00000 00000</span>
                  <span className="underlines">
                    <span className="underlines__line"></span>
                    <span className="underlines__line"></span>
                    <span className="underlines__line"></span>
                    {responseStatus && (
                      <ResponseMessage
                        fixed={isFixedHeader}
                        status={responseStatus}
                      >
                        {status[responseStatus]}
                      </ResponseMessage>
                    )}
                  </span>
                </Label>
              </Input>

              <CheckCodeButton
                key="checkCodeBlockSelf"
                toLogin={auth && auth.login}
                checkCode={checkCode && checkCode.replace(/\s+/g, '')}
                geo={geo}
                fixed={isFixedHeader}
                validate={validate()}
                setInspectionId={setInspectionId}
                setResponseMessage={setResponseMessage}
                setResponseStatus={setResponseStatus}
                setCheckCode={setCheckCode}
                setShowTextForCallForm={setShowTextForCallForm}
                isShowTextForCallForm={isShowTextForCallForm}
                responseStatus={responseStatus}
                isShowFeedbackForm={isShowFeedbackForm}
                setShowFeedbackForm={setShowFeedbackForm}
                feedbackRef={rightColRef}
                setFixedHeader={setFixedHeader}
                isOpenFeedbackForm={isOpenFeedbackForm}
                setOpenFeedbackForm={setOpenFeedbackForm}
                isLoading={isLoading}
                sendCheckRequest={sendCheckRequest}
              />
            </CheckCodeBlock>
          </CheckCodeBlockWrapper>
          {!token && !isShowTextForCallForm && (
            <TextWhyAutorization
              ref={whyAutorizationRef}
              onClick={() => setShowWhyAutorization(!isShowWhyAuthorization)}
            >
              {isBlockWhyAuth && (
                <>
                  {question.whyAutorization}
                  <QuestionButton
                    onClick={() =>
                      setShowWhyAutorization(!isShowWhyAuthorization)
                    }
                  />
                  {isShowWhyAuthorization && (
                    <InfoBlock type="whyAutorization" />
                  )}
                </>
              )}
            </TextWhyAutorization>
          )}
          {isShowTextForCallForm && (
            <>
              {isShowFeedbackForm && (
                <FeedBackForm
                  lang={lang}
                  token={token}
                  inspectionId={inspectionId}
                  checkCode={checkCode}
                  geo={geo}
                  setShowFeedbackForm={setShowFeedbackForm}
                  setShowTextForCallForm={setShowTextForCallForm}
                  checkInputRef={checkInputRef}
                  setResponseStatus={setResponseStatus}
                  setResponseMessage={setResponseMessage}
                  setOpenFeedbackForm={setOpenFeedbackForm}
                  setCheckCode={setCheckCode}
                  isShowFeedbackForm={isShowFeedbackForm}
                  isShowResponseFeedback={isShowResponseFeedback}
                  setShowResponseFeedback={setShowResponseFeedback}
                />
              )}
              {responseStatus && !responseMessage && (
                <TextForCallForm
                  onClick={() => {
                    setShowFeedbackForm(true);
                    setOpenFeedbackForm(true);
                    form.current.scrollIntoView({
                      block: 'start',
                      behavior: 'smooth',
                    });
                  }}
                  dangerouslySetInnerHTML={{
                    __html: isContrafact,
                  }}
                />
              )}
            </>
          )}
        </RightCol>
      </UniqueCodeWrapper>
      <GifEngineWrapper>
        <EngineImage src={LogoTG} alt="Engine" />
      </GifEngineWrapper>
    </StyledGrid>
  );
};

export default Form;
