import React, { useContext, useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import Locales from 'Locales';
import MainContext from 'Src/app/MainContextProvider';
import isNode from 'detect-node';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import Loader from 'Common/Loader';
import Consent from 'Common/Consent';

const Wrapper = styled.div`
  font-family: ${props => props.theme.fonts.font_head};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  width: auto;
  ${media.tablet`
    width:  ${props => (props.fixed ? '250px' : 'auto')};
  `}
  ${media.laptop`
    width: ${props => (props.fixed ? '283px' : 'auto')};
  `}
`;

const Send = styled.button`
  width: 100%;
  height: 40px;
  margin-bottom: ${({ authenticated }) => authenticated && '16px'};
  border: 1px solid
    ${props => (props.fixed ? props.theme.white : props.theme.primary_petrol)};
  color: #fff;
  color: ${props =>
    props.isDisable && props.fixed && props.theme.primary_petrol};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.font_head};
  background-color: ${props => props.theme.primary_petrol};
  text-align: center;
  letter-spacing: 0.02em;
  cursor: pointer;
  outline: none;
  pointer-events: ${props => !props.isDisable && 'none'};
  background-color: ${props => !props.isDisable && props.theme.silver};
  background-color: ${props =>
    !props.isDisable && props.fixed && props.theme.silver};
  background-color: ${props =>
    props.isDisable && props.fixed && props.theme.white};
  border: 1px solid ${props => !props.isDisable && props.theme.silver};
  &:hover {
    background-color: ${props =>
      !props.isLoading && props.theme.secondary_blue_light};
    border: 1px solid ${props => props.theme.secondary_blue_light};
    color: ${props => props.isDisable && props.fixed && props.theme.white};
  }
  ${media.tablet`
    width: ${({ authenticated }) => (authenticated ? '230px' : '250px')};
    margin-bottom: 0;
  `}
  ${media.laptop`
    width: 283px;
  `}
`;

const Support = styled.a`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${props => props.fixed && '20px'};
  border: 1px solid ${props => props.theme.primary_petrol};
  color: ${props => props.theme.primary_petrol};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.font_head};
  background-color: ${props => props.theme.white};
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.secondary_blue_light};
    border: 1px solid ${props => props.theme.secondary_blue_light};
  }
  ${media.tablet`
    width: 220px;
    margin-top: ${props => props.fixed && '0'};
  `}
  ${media.ultrawidescreen`
    width: 280px;
  `}
`;

const CheckCodeButton = ({
  checkCode,
  toLogin,
  fixed,
  validate,
  responseStatus,
  setShowTextForCallForm,
  setOpenFeedbackForm,
  setShowFeedbackForm,
  feedbackRef,
  setFixedHeader,
  isOpenFeedbackForm,
  sendCheckRequest,
  isLoading,
}) => {
  const {
    state: { auth, token, lang, agree, showConsent },
    dispatch,
  } = useContext(MainContext);
  const { sendCodeBlock } = Locales[lang].Form;

  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(token ? sendCodeBlock.checkCode : sendCodeBlock.autorization);
  }, [token]);

  return !token ? (
    <Wrapper fixed={fixed}>
      {showConsent && <Consent fixed={fixed} />}
      <Send
        // eslint-disable-next-line consistent-return
        onClick={() => {
          if (!token) {
            if (!isNode && window.localStorage) {
              window.localStorage.setItem('checkCode', checkCode);
              setShowFeedbackForm(false);
              sendCheckRequest();
              setShowTextForCallForm(true);
            }
            toLogin();
            Cookie.set('kdx_agree', true);
            Cookie.set(
              'VW_AUTH',
              `${window.location.href}${
                !window.location.href.match('profile') ? 'profile' : ''
              }`,
            );
            dispatch({ type: 'showConsent', payload: false });
          }
        }}
        fixed={fixed}
        authenticated={!!token}
        isDisable={validate && (!showConsent || agree)}
      >
        {isLoading ? <Loader invert /> : title}
      </Send>
    </Wrapper>
  ) : (
    <Wrapper fixed={fixed}>
      {!isOpenFeedbackForm && !fixed && (
        <>
          {showConsent && <Consent fixed={fixed} />}
          <Send
            // eslint-disable-next-line consistent-return
            onClick={() => {
              if (!isNode && window.localStorage) {
                window.localStorage.setItem('checkCode', checkCode);
                sendCheckRequest();
                Cookie.set('kdx_agree', true);
                dispatch({ type: 'showConsent', payload: false });
              }
            }}
            fixed={fixed}
            authenticated={!!token}
            isDisable={validate && (!showConsent || agree)}
            isLoading={isLoading}
          >
            {isLoading ? <Loader invert /> : title}
          </Send>
        </>
      )}
      {responseStatus && responseStatus !== 'n' && fixed ? (
        <Support
          fixed={fixed}
          onClick={() => {
            setShowFeedbackForm(true);
            setOpenFeedbackForm(true);
            setFixedHeader(false);
            feedbackRef.current.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }}
        >
          {sendCodeBlock.support}
        </Support>
      ) : (
        fixed && (
          <>
            {showConsent && <Consent fixed={fixed} />}
            <Send
              // eslint-disable-next-line consistent-return
              onClick={() => {
                if (!isNode && window.localStorage) {
                  window.localStorage.setItem('checkCode', checkCode);
                  sendCheckRequest();
                  Cookie.set('kdx_agree', true);
                  dispatch({ type: 'showConsent', payload: false });
                }
              }}
              fixed={fixed}
              authenticated={!!token}
              isDisable={validate && (!showConsent || agree)}
              isLoading={isLoading}
            >
              {isLoading ? <Loader invert /> : title}
            </Send>
          </>
        )
      )}
    </Wrapper>
  );
};
export default CheckCodeButton;
