import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import MainContext from 'Src/app/MainContextProvider';
import Locales from 'Locales';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

const Wrapper = styled.div`
  position: ${props => (!props.fixed ? 'absolute' : 'fixed')};
  left: 0;
  top: ${props => (props.fixed ? '65px' : '150%')};
  z-index: 22;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  font-family: ${props => props.theme.fonts.font};
  font-size: 16px;
  line-height: 24px;
  background: ${props => props.theme.formSecond};
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    left: ${props => (props.type === 'findCode' ? '203px' : '224px')};
    left: ${props =>
      props.type !== 'findCode' && props.lang === 'en' && '188px'};
    left: ${props =>
      props.type === 'findCode' && props.lang === 'en' && '257px'};
    left: ${props => props.fixed && '145px'};
    left: ${props => props.fixed && props.lang === 'en' && '110px'};
    width: 20px;
    height: 20px;
    background: ${props => props.theme.formSecond};
    ${media.tablet`
      left: ${props => (props.type === 'findCode' ? '202px' : '224px')};
      left: ${props =>
        props.type !== 'findCode' && props.lang === 'en' && '188px'};
      left: ${props =>
        props.type === 'findCode' && props.lang === 'en' && '257px'};
      left: ${props => props.fixed && '129px'};
      left: ${props => props.fixed && props.lang === 'en' && '95px'};
    `}
    ${media.laptop`
      left: ${props => (props.type === 'findCode' ? '200px' : '224px')};
      left: ${props =>
        props.type !== 'findCode' && props.lang === 'en' && '185px'};
      left: ${props =>
        props.type === 'findCode' && props.lang === 'en' && '257px'};
      left: ${props => props.fixed && '173px'};
      left: ${props => props.fixed && props.lang === 'en' && '140px'};
    `}
  }
  ${media.tablet`
    position: absolute;
    top: ${props => (!props.fixed ? '45px' : '150%')};
    width: 345px;
  `}
  ${media.widescreen`
    width: 425px;
  `}
`;

const Sticker = styled.img`
  width: 190px;
  position: relative;
  left: -5px;
`;

const Description = styled.p`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin: 0;
`;

const InfoBlock = ({ type, fixed }) => {
  const {
    state: { lang },
  } = useContext(MainContext);
  return (
    <StaticQuery
      query={graphql`
        query {
          StickerImage: file(
            relativeDirectory: { regex: "sticker/" }
            base: { eq: "bad_sticker2.png" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        return (
          <Wrapper type={type} fixed={fixed} lang={lang}>
            {type === 'findCode' && (
              <Sticker src={data.StickerImage.publicURL} alt="sticker" />
            )}
            <Description
              dangerouslySetInnerHTML={{
                __html: Locales[lang].Form.tooltipInfo[type],
              }}
            />
          </Wrapper>
        );
      }}
    />
  );
};
export default InfoBlock;
