/** eslint-disable */
import React from 'react';
import Locales from 'Locales';
import styled from 'styled-components';
import SlideDown from 'UI_Elements/SlideDown';
import { media } from 'Src/app/Styles/Theme';
import scrollToY from 'Src/helpers/scrollToY';
import IconClose from 'Src/assets/img/ico/icon_menu-close.svg';
import IconSuccess from 'Src/assets/img/ico/icon_success.svg';
import Loader from 'Components/Common/Loader';
import UploadInput from './UploadInput';

const Close = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 20px;
`;

const ShowForm = styled.div`
  position: relative;
  padding: 8px 30px 8px 48px;
  text-align: left;
  width: 100%;
  font-size: 15px;
  color: #fff;
  cursor: ${props => (props.formHaveBeenSend ? 'default' : 'pointer')};
  background: ${props => (props.formHaveBeenSend ? '#32a24a' : 'pointer')};
  ${media.tablet`
    padding: 8px 48px;
  `}
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 16px;
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${props => props.icon});
  }
`;

const FeelTheForm = styled.div`
  font-family: ${props => props.theme.fonts.font_head};
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 32px;
  ${media.tablet`
    margin-bottom: 64px;
    font-size: 30px;
  `}
`;

const FeedBack = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  outline: none;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.secondary_blue_light};
  border-bottom-color: ${props => props.error && '#f63269'};
  display: block;
  width: 100%;
  height: 36px;
  background-color: transparent;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.font};
  transition: all 0.2s;
  color: ${props => props.theme.petrol60};
  &::placeholder {
    color: ${props => props.theme.petrol60};
  }
`;

const StyledCheckBox = styled.div`
  position: relative;
  display: flex;
  flex-basis: auto;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: ${props => props.theme.primary_petrol};
  min-width: 100%;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 24px;
  }
  ${media.tablet`
    min-width: 28%;
    margin-bottom: 0;
  `}
  &:before {
    content: '';
    position: relative;
    margin-right: 20px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid
      ${props => (props.active ? props.theme.primary_petrol : '#8DBFCF')};
    ${media.tablet`
      width: 40px;
      height: 40px;
    `}
  }
  &:after {
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: ${props =>
      props.active ? props.theme.primary_petrol : 'transparent'};
    ${media.tablet`
      top: 16px;
      left: 16px;
      width: 10px;
      height: 10px;
    `}
  }
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  ${media.tablet`
    margin-bottom: 45px;
  `}
  h1 {
    flex-basis: 100%;
    margin-top: 0;
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 36px;
  }
`;

const UploadGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  ${media.tablet`
    margin-bottom: 40px;
  `}
`;

const ExtraInfo = styled.textarea`
  font-family: ${props => props.theme.fonts.font};
  width: 100%;
  min-height: 90px;
  padding: 15px;
  background-color: transparent;
  font-style: italic;
  font-size: 16px;
  line-height: 25px;
  resize: none;
  border: 1px solid ${props => props.theme.secondary_blue_light};
  border-color: ${props => props.error && '#f63269'};
  color: ${props => props.theme.secondary_blue_light};
  outline: none;
  border-radius: 0;
  &::placeholder {
    color: ${props => props.theme.secondary_blue_light};
    opacity: 0.5;
  }
  ${media.tablet`
    padding: 20px;
  `}
`;

const Send = styled.button`
  width: 100%;
  height: 40px;
  outline: none;
  border: 1px solid ${props => props.theme.primary_petrol};
  color: ${props =>
    !props.isThereErrors ? props.theme.primary_petrol : '#fff'};
  font-size: 16px;
  font-family: ${props => props.theme.fonts.font_head};
  background-color: ${props =>
    !props.isThereErrors ? 'transparent' : '#004666'};
  text-align: center;
  cursor: ${props => (!props.isThereErrors ? 'default' : 'pointer')};
  ${props =>
    props.isThereErrors
      ? `&:hover {
           background: #8dbfcf;
            border-color: #8dbfcf;
         }`
      : ''};
`;

const RequiredFields = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${props => props.theme.primary_petrol};
  font-family: ${props => props.theme.fonts.font};
`;

const Wrapper = styled.div`
  position: ${props => !props.isResponse && 'absolute'};
  left: 0;
  z-index: 24;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: ${props => !props.isResponse && '40px 16px'};
  background: ${props => props.theme.bg_color};
  ${media.desktop`
    width: ${props => !props.isResponse && '1025px'};
    padding: ${props => !props.isResponse && '80px 104px'};
  `}
  ${media.ultrawidescreen`
    width: ${props => !props.isResponse && '1268px'};
  `}
`;

const ShowError = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  color: ${props => (props.error ? '#f63269;' : '#004666')};
  ${media.tablet`
    width:${props => (props.wide ? '100%' : '48%')} ;
  `}

  &:not(:focus) {
    &:before {
      display: ${props => (props.error ? 'block' : 'none')};
      content: ${props => `'${props.errorText}'`};
      height: 12px;
      font-size: 12px;
      color: #f63269;
      position: absolute;
      bottom: -8px;
    }
  }
`;

const getTextError = (status, locale) => {
  switch (true) {
    case status === 413:
      return Locales[locale].Form.FeedBack.largeFileError;
    case status === 403:
      return Locales[locale].Form.FeedBack.authError;
    default:
      return Locales[locale].Form.FeedBack.serverError;
  }
};
class FeedBackForm extends React.Component {
  constructor(props) {
    super(props);
    this.scanRef = React.createRef();
    this.photoRef = React.createRef();
    this.otherPhotoRef = React.createRef();
    this.state = {
      responseMessage: null,
      showForm: true,
      agreement: true,
      trade: 1,
      uploadPhotoName: false,
      uploadScanName: false,
      uploadOtherPhotoNames: false,
      place: '',
      place_error: false,
      address: '',
      address_error: false,
      message: '',
      message_error: false,
      excludeFiles: [],
      isLoading: false,
    };
  }

  submit = locale => {
    this.setState({
      isLoading: true,
    });
    const formData = new FormData();
    formData.append('code', this.props.checkCode.replace(/ /g, ''));
    formData.append('place', this.state.place);
    formData.append('address', this.state.address);
    formData.append('message', this.state.message);
    formData.append('trade', this.state.trade);
    formData.append('agreement', this.state.agreement);
    formData.append('inspectionId', this.props.inspectionId);
    formData.append('images[receipt]', this.scanRef.current.files[0]);
    formData.append('images[product]', this.photoRef.current.files[0]);
    const amountFiles = this.otherPhotoRef.current.files.length;
    for (let i = 0; i < amountFiles; i++) {
      if (this.state.excludeFiles.indexOf(i) === -1) {
        formData.append(
          'images[additional][]',
          this.otherPhotoRef.current.files[i],
        );
      }
    }

    if (this.props.geo) {
      formData.append('latitude', this.props.geo.latitude);
      formData.append('longitude', this.props.geo.longitude);
    }

    if (this.props.token === null || !this.props.token) {
      scrollToY(this.props.scrollTop, 300, 'easeInOutQuint', () =>
        this.setState({
          responseMessage: Locales[locale].Form.FeedBack.authError,
          showForm: false,
          isLoading: false,
        }),
      );
      return null;
    }
    const myHeaders = new Headers();
    myHeaders.append('X-Lang', locale);
    myHeaders.append('Authorization', `Bearer ${this.props.token}`);
    const myInit = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
    };
    const promise = () =>
      fetch(`${process.env.GATSBY_CHECK_CODE_URL}/feedback`, myInit).then(
        res => {
          if (res.status !== 201) {
            scrollToY(this.props.scrollTop, 300, 'easeInOutQuint', () =>
              this.setState({
                showForm: false,
                responseMessage: getTextError(res.status, locale),
                isLoading: false,
              }),
            );
          }
          return res.json();
        },
      );
    promise().then(res => {
      scrollToY(this.props.scrollTop, 300, 'easeInOutQuint', () => {
        this.setState({
          responseMessage: res.message,
          showForm: false,
          isLoading: false,
        });
        this.props.setResponseMessage(true);
        this.props.setOpenFeedbackForm(false);
        this.props.setCheckCode(null);
        this.props.setShowResponseFeedback(true);

        window.dataLayer_kdx = window.dataLayer_kdx || [];
        window.dataLayer_kdx.push({
          event: 'send_ga',
          eventCategory: 'Request Form',
          eventAction: 'success',
          eventLabel: undefined,
        });
        window.dataLayer_kdx.push({
          event: 'send_ga',
          eventCategory: 'Request Form',
          eventAction: 'input name',
          eventLabel: this.state.place,
        });
        window.dataLayer_kdx.push({
          event: 'send_ga',
          eventCategory: 'Request Form',
          eventAction: 'input address purchase',
          eventLabel: this.state.address,
        });
      });
    });
  };

  checkErrors = () =>
    this.state.uploadPhotoName &&
    this.state.uploadScanName &&
    !this.state.place_error &&
    !this.state.address_error &&
    this.state.place &&
    this.state.message &&
    !this.state.message_error &&
    this.state.agreement;

  putInState = (val, field) => {
    const obj = { [field]: val };
    switch (field) {
      case 'email':
        if (
          !/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(
            val,
          )
        ) {
          obj['email_error'] = true;
        } else {
          obj['email_error'] = false;
        }
        break;
      case 'phone':
        if (!/^\+\d\(\d{3}\)-\d{3}-\d{2}-\d{2}$/.test(val)) {
          obj['phone_error'] = true;
        } else {
          obj['phone_error'] = false;
        }
        break;
      default:
        if (!val) {
          obj[`${field}_error`] = true;
        } else {
          obj[`${field}_error`] = false;
        }
    }
    this.setState(obj);
  };

  addBrToResponse = str => {
    const message = str.match(/Ваша заявка успешно принята\./);
    if (message) return str.replace(message[0], `${message[0]}<br>`);
    return str;
  };

  render() {
    return (
      <Wrapper isResponse={!this.state.showForm}>
        {!this.state.showForm && this.props.isShowResponseFeedback && (
          <ShowForm
            key="qasd"
            icon={IconSuccess}
            formHaveBeenSend={this.state.responseMessage}
            onClick={() =>
              this.state.responseMessage || this.setState({ showForm: true })
            }
            dangerouslySetInnerHTML={{
              __html:
                this.addBrToResponse(this.state.responseMessage) ||
                Locales[this.props.lang].Form.isContrafact,
            }}
          />
        )}
        {!this.state.responseMessage && (
          <Close
            src={IconClose}
            alt="close-icon"
            onClick={() => {
              this.props.setShowFeedbackForm(false);
              this.props.setShowTextForCallForm(false);
              this.props.setCheckCode(null);
              this.props.checkInputRef.current.value = null;
              this.props.setResponseStatus(null);
              this.props.setOpenFeedbackForm(false);
              window.localStorage.setItem('checkCode', '');
            }}
          />
        )}
        <SlideDown key="qaz" open={this.state.showForm}>
          <FeedBack>
            <FeelTheForm
              dangerouslySetInnerHTML={{
                __html: Locales[this.props.lang].Form.FeedBack.fill,
              }}
            />
            <Section>
              <h1>{Locales[this.props.lang].Form.FeedBack.dataH}</h1>
              <StyledCheckBox
                active={this.state.trade === 1}
                onClick={() => this.setState({ trade: 1 })}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: Locales[this.props.lang].Form.FeedBack.shop,
                  }}
                />
              </StyledCheckBox>
              <StyledCheckBox
                active={this.state.trade === 2}
                onClick={() => this.setState({ trade: 2 })}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: Locales[this.props.lang].Form.FeedBack.webShop,
                  }}
                />
              </StyledCheckBox>
              <StyledCheckBox
                active={this.state.trade === 3}
                onClick={() => this.setState({ trade: 3 })}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: Locales[this.props.lang].Form.FeedBack.service,
                  }}
                />
              </StyledCheckBox>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexWrap: 'wrap',
                  marginTop: '25px',
                }}
              >
                <ShowError
                  error={this.state.place_error}
                  errorText={
                    Locales[this.props.lang].Form.FeedBack.requiredField
                  }
                >
                  <StyledInput
                    placeholder={Locales[this.props.lang].Form.FeedBack.store}
                    onChange={e => this.setState({ place: e.target.value })}
                    onBlur={e => this.putInState(e.target.value, 'place')}
                    value={this.state.place}
                  />
                </ShowError>
                <ShowError
                  error={this.state.address_error}
                  errorText={
                    Locales[this.props.lang].Form.FeedBack.requiredField
                  }
                >
                  <StyledInput
                    placeholder={Locales[this.props.lang].Form.FeedBack.address}
                    onChange={e => this.setState({ address: e.target.value })}
                    onBlur={e => this.putInState(e.target.value, 'address')}
                    value={this.state.address}
                  />
                </ShowError>
              </div>
            </Section>
            <UploadGroup>
              <UploadInput
                id="uploadScanName"
                ref={this.scanRef}
                fileNames={this.state.uploadScanName}
                title={Locales[this.props.lang].Form.FeedBack.photoCheck}
                notification={
                  Locales[this.props.lang].Form.FeedBack.notification1
                }
                state={this}
                onChange={() => {
                  if (this.scanRef.current.files.length) {
                    this.setState({
                      uploadScanName: [...this.scanRef.current.files].map(
                        item => item.name,
                      ),
                    });
                  }
                }}
                checkErrors={this.checkErrors}
              />
              <UploadInput
                id="uploadPhotoName"
                ref={this.photoRef}
                fileNames={this.state.uploadPhotoName}
                title={Locales[this.props.lang].Form.FeedBack.photoItem}
                notification={
                  Locales[this.props.lang].Form.FeedBack.notification2
                }
                state={this}
                onChange={() => {
                  if (this.photoRef.current.files.length) {
                    this.setState({
                      uploadPhotoName: [...this.photoRef.current.files].map(
                        file => file.name,
                      ),
                    });
                  }
                }}
                checkErrors={this.checkErrors}
              />
              <UploadInput
                id="uploadOtherPhotoNames"
                ref={this.otherPhotoRef}
                fileNames={this.state.uploadOtherPhotoNames}
                title={Locales[this.props.lang].Form.FeedBack.otherPhoto}
                notification={
                  Locales[this.props.lang].Form.FeedBack.notification3
                }
                onChange={() => {
                  if (this.otherPhotoRef.current.files.length) {
                    this.setState({
                      uploadOtherPhotoNames: [
                        ...this.otherPhotoRef.current.files,
                      ].map(file => file.name),
                    });
                  }
                }}
                excludeFiles={this.state.excludeFiles}
                state={this}
                multiple
                checkErrors={this.checkErrors}
              />
            </UploadGroup>
            <Section style={{ flexDirection: 'column' }}>
              <h1
                style={{
                  marginBottom: '20px',
                }}
              >
                {Locales[this.props.lang].Form.FeedBack.extraInfoH}
              </h1>
              <ShowError
                style={{ width: '100%' }}
                error={this.state.message_error}
                errorText={Locales[this.props.lang].Form.FeedBack.requiredField}
              >
                <ExtraInfo
                  placeholder={Locales[this.props.lang].Form.FeedBack.extraInfo}
                  type="text"
                  onChange={e => this.putInState(e.target.value, 'message')}
                  value={this.state.message}
                />
              </ShowError>

              <RequiredFields
                dangerouslySetInnerHTML={{
                  __html: Locales[this.props.lang].Form.FeedBack.required,
                }}
              />
            </Section>
            <Send
              isThereErrors={this.checkErrors()}
              onClick={() => {
                if (this.checkErrors()) {
                  this.submit(this.props.lang);
                }
              }}
            >
              {this.state.isLoading ? (
                <Loader />
              ) : (
                Locales[this.props.lang].Form.FeedBack.submit
              )}
            </Send>
          </FeedBack>
        </SlideDown>
      </Wrapper>
    );
  }
}
export default FeedBackForm;

FeedBackForm.defaultProps = {
  inspectionId: null,
  scrollTop: '800',
};
