import React, { useState, useRef, useContext, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';
import PropTypes from 'prop-types';
import IconDelete from 'Src/assets/img/ico/icon_cancel.svg';

const Upload = styled.label`
  cursor: pointer;
  height: 40px;
  margin-bottom: -10px;
  position: relative;
  display: block;
  outline: none;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.font_bold};
  input {
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 23;
    position: relative;
    display: none;
  }
  & :hover {
    color: #8dbecf;
  }
  &:after {
    cursor: pointer;
    content: ${props => `'${props.title}'`};
    position: absolute;
    left: 60px;
    top: 0;
    height: 100%;
    font-weight: bold;
  }
  &:before {
    cursor: pointer;
    content: '';
    background: transparent url(${props => props.ico}) no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${media.tablet`
    width: 48%;
  `}
  margin-bottom: 25px;
`;

const FileList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 60px;
`;

const FileTitle = styled.span`
  display: block;
  max-width: 340px;
  font-family: 'VW-Sans Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.secondary_blue_dark};
`;

const Notification = styled.p`
  padding-left: 60px;
  margin: 8px 0;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.grey70};
  span {
    color: ${props => props.theme.secondary_blue_dark};
    white-space: nowrap;
  }
`;

const ButtonDeleteFile = styled.img`
  margin-left: 5px;
  cursor: pointer;
`;

const UploadInput = React.forwardRef(
  (
    {
      title,
      fileNames,
      notification,
      id,
      multiple,
      excludeFiles,
      state,
      checkErrors,
      ...rest
    },
    ref,
  ) => (
    <StaticQuery
      query={graphql`
        query {
          Download: file(
            relativeDirectory: { regex: "ico/" }
            base: { eq: "download.jpg" }
          ) {
            publicURL
          }
          DownloadSuccess: file(
            relativeDirectory: { regex: "ico/" }
            base: { eq: "download-success.jpg" }
          ) {
            publicURL
          }
        }
      `}
      render={data => {
        const [isClean, setClean] = useState(true);

        return (
          <Wrapper>
            <Upload
              {...rest}
              htmlFor={id}
              title={title}
              ico={data.Download.publicURL}
              icoSuccess={data.DownloadSuccess.publicURL}
            >
              <input
                id={id}
                ref={ref}
                accept=".jpg, .jpeg, .png"
                type="file"
                multiple={multiple}
                onChange={() => {
                  if (multiple) {
                    state.setState({
                      excludeFiles: [],
                    });
                  }
                  setClean(false);
                }}
              />
            </Upload>
            {!isClean && (
              <FileList>
                {ref.current &&
                  [...ref.current.files].map(
                    (item, index) =>
                      ((multiple && excludeFiles.indexOf(index) === -1) ||
                        !multiple) && (
                        <FileWrapper key={+index}>
                          <FileTitle
                            dangerouslySetInnerHTML={{
                              __html: item.name,
                            }}
                          />
                          <ButtonDeleteFile
                            src={IconDelete}
                            alt="icon_delete_file"
                            onClick={() => {
                              state.setState({
                                [ref.current.id]: false,
                              });
                              if ([...ref.current.files].length > 1) {
                                if (excludeFiles.indexOf(index) === -1) {
                                  state.setState({
                                    excludeFiles: [...excludeFiles, index],
                                  });
                                }
                              } else {
                                ref.current.value = null;
                                setClean(true);
                                state.setState({
                                  fullCheck: checkErrors(),
                                });
                              }
                            }}
                          />
                        </FileWrapper>
                      ),
                  )}
              </FileList>
            )}
            <Notification
              dangerouslySetInnerHTML={{
                __html: notification,
              }}
            />
          </Wrapper>
        );
      }}
    />
  ),
);

export default UploadInput;

UploadInput.defaultProps = {
  notification: null,
};
UploadInput.propTypes = {
  title: PropTypes.string.isRequired,
  notification: PropTypes.string,
};
