import React, { useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import MainContext from 'Src/app/MainContextProvider';
import { media } from 'Src/app/Styles/Theme';
import Locales from 'Locales';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(52, 57, 59, 0.7);
`;

const Popup = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  background: ${({ theme }) => theme.bg_color};
  overflow-y: scroll;

  ${media.tablet`
    width: 600px;
    height: ${({ lang }) => (lang === 'ru' ? '90vh' : '75vh')};
    max-height: 700px;
  `}
`;

const Title = styled.h2`
  margin: 0 0 24px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`;

const Close = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.secondary_blue_dark};
    }
  }
  svg {
    fill: ${({ theme }) => theme.primary_petrol};
  }
`;

const Content = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #335f7d;
  text-align: justify;
  font-family: ${({ theme: { fonts } }) => fonts.font_head};

  p {
    text-indent: 20px;
    margin: 0;
  }
`;

const TermsPopup = ({ closePopup }) => {
  const {
    state: { lang },
  } = useContext(MainContext);
  const popupRef = useRef(null);

  const handleClickOutside = event => {
    if (popupRef && !popupRef.current.contains(event.target)) {
      closePopup();
    }
  };

  useEffect(() => {
    disableBodyScroll(popupRef.current);
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <Overlay>
      <Popup ref={popupRef} lang={lang}>
        <Close onClick={closePopup}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M19.895 19.188L12.729 12.022L19.854 4.853L19.146 4.147L12.022 11.315L4.833 4.125L4.125 4.833L11.317 12.024L4.171 19.215L4.88 19.92L12.024 12.732L19.188 19.896L19.895 19.188Z" />
          </svg>
        </Close>

        <Title
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Form.FeedBack.termsTitle,
          }}
        />
        <Content
          dangerouslySetInnerHTML={{
            __html: Locales[lang].Form.FeedBack.disclaimer,
          }}
        />
      </Popup>
    </Overlay>
  );
};

export default TermsPopup;
