import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

const StyledSlideDown = styled(SlideDown)`
  display: flex;
  width: 100%;
`;

const Down = ({ open, children }) => {
  return (
    <StyledSlideDown className="my-dropdown-slidedown">
      {open ? children : null}
    </StyledSlideDown>
  );
};

export default Down;

Down.defaultProps = {
  open: false,
};
Down.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.element.isRequired,
};
