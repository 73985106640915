import styled from 'styled-components';
import { media } from 'Src/app/Styles/Theme';

export const StyledGrid = styled.div`
  height: ${props => (props.fixed ? `${props.heightForm}px` : 'auto')};
  padding: 40px 16px;
  ${media.desktop`
    padding: 40px 0;
  `}
`;

export const LeftCol = styled.div`
  width: 100%;
  ${media.tablet`
    width: auto;
  `}
`;

export const RightCol = styled.div`
  width: 100%;
  ${media.tablet`
    width: 370px;
  `}
  ${media.widescreen`
    width: 370px;
  `}
  ${media.ultrawidescreen`
    width: 435px;
  `}
`;

export const Description = styled.div`
  font-family: VW-Head;
  margin: 40px 32px;
  padding-left: 20px;
  padding-right: 20px;
  ${media.tablet`
    padding-left: 0px;
    padding-right: 0px;
  `}
`;

export const LoadingBlock = styled.div`
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-size: 16px;
`;

export const InputWrapper = styled.div`
  position: static;
  width: 100%;
  background-color: ${props => props.theme.white};;
  box-sizing: border-box;
  padding: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #c6dfe7;
  &:nth-child(odd) {
    border-bottom: 0;
  }
  ${media.tablet`
    position: relative;
  `}
  ${media.laptop`
    width: 944px;
  `}
  ${media.desktop`
    width: 1025px;
  `}
  ${media.widescreen`
    width: 1096px;
  `}
  ${media.ultrawidescreen`
    width: 1268px;
  `}
`;

export const GifEngineWrapper = styled(InputWrapper)`
  ${media.desktop`
    width: 1025px;
  `}
  ${media.ultrawidescreen`
    width: 1268px;
  `}
`;

export const UniqueCodeWrapper = styled(InputWrapper)`
  user-select: none;
  border: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 40px;

  ${media.tablet`
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid ${props => props.theme.secondary_petrol};
    padding: 40px;
  `}
  ${media.laptop`
    flex-direction: row;
  `}
  ${media.desktop`
    width: 1025px;
  `}
  ${media.ultrawidescreen`
    width: 1268px;
  `}
`;

export const Input = styled.div`
  position: relative;
  width: ${props => props.fixed && '275px'};
  display: ${props => props.fixed && 'flex'};
  flex-direction: ${props => props.fixed && 'column'};
  justify-content: ${props => props.fixed && 'space-between'};
  align-items: ${props => props.fixed && 'flex-start'};
  margin-bottom: 30px;
  margin-bottom: ${props => !props.fixed && props.status && '30px'};
  margin-top: ${props => (props.fixed ? '24px' : '40px')};
  background: ${props =>
    props.fixed ? props.theme.primary_petrol : 'transparent'}; */
  ${media.phablet`
    margin-bottom: ${props => props.fixed && '30px'};
  `}
  ${media.tablet`
    margin-bottom: ${props => (!props.fixed ? '30px' : 0)};
    letter-spacing: 0.04em;
    margin-top: ${props => (props.fixed ? '0' : '40px')};
  `}
  ${media.laptop`
    margin-top: ${props => !props.fixed && '0'};
  `}
  ${media.desktop`
    flex-direction: ${props => props.fixed && 'row'};
    align-items: ${props => props.fixed && 'flex-end'};
    width: ${props => props.fixed && '416px'};
  `}
  input {
    position: relative;
    display: block;
    width: ${props => (props.fixed ? '260px' : '275px')};
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    font-family: ${props => props.theme.fonts.font_head};
    font-size: ${props => (props.fixed ? '29px' : '31px')};
    line-height: ${props => (props.fixed ? '40px' : '56px')};
    letter-spacing: 0.01em;
    outline: none;
    color: ${props => props.theme.secondary_petrol};
    ${media.mobile`
      width: ${props => !props.fixed && '325px'};
      font-size: ${props => (props.fixed ? '29px' : '36px')};
    `}
    ${media.tablet`
      width: ${props => !props.fixed && '392px'};
      font-size: ${props => (props.fixed ? '29px' : '42px')};
    `}
    ${media.ultrawidescreen`
      width: ${props => !props.fixed && '440px'};
      font-size: ${props => (props.fixed ? '29px' : '49px')};
    `}
    
    &:focus {
      color: ${props => props.theme.primary_petrol};
      color: ${props =>
        props.fixed ? props.theme.white : props.theme.primary_petrol};
      & + label {
        top: ${props => props.fixed && 'none'};
      }
      & + label .zero {
        display: none;
      }
      & + label .underlines {
        &__line {
          background-color: ${props =>
            props.fixed ? props.theme.white : props.theme.primary_petrol};
        }
        
      }
    }
    &:not(:invalid) {
      color: ${props =>
        props.fixed ? props.theme.white : props.theme.primary_petrol};
      color: ${props =>
        props.fixed ? props.theme.white : props.theme.primary_petrol};
      color: ${props => props.status === 'n' && props.theme.green};
      color: ${props =>
        props.fixed && props.status === 'n' && props.theme.green_light};
      color: ${props => props.status === 'd' && props.theme.orange_dark};
      color: ${props =>
        props.fixed && props.status === 'd' && props.theme.orange_dark50};
      color: ${props => props.status === 'k' && props.theme.red};
      color: ${props =>
        props.fixed && props.status === 'k' && props.theme.red_light};
      & + label {
        top: ${props => props.fixed && 'none'};
      }
      & + label .zero {
        display: none;
      }
      & + label .underlines {
        right: ${props => (props.fixed ? '2px' : '-3px')}
        &__line {
          background-color: ${props =>
            props.fixed ? props.theme.white : props.theme.primary_petrol};
          background: ${props => props.status === 'n' && props.theme.green};
        }
        
      }
    }
  }
`;

export const Title = styled.p`
  font-family: ${props => props.theme.fonts.font_head};
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  line-height: 40px;
  letter-spacing: 0.02em;
  margin: 0;
  margin-bottom: 16px;
  ${media.phablet`
    font-size: 30px;
    margin-bottom: 16px;
    br {
      display: none;
    }
  `}

  ${media.tablet`
    font-size: 30px;
    margin-bottom: 16px;
    br {
      display: block;
    }
  `}
  ${media.laptop`
    margin-bottom: 57px;
    
  `}
  ${media.widescreen`
    margin-bottom: 64px;
  `}
  ${media.ultrawidescreen`
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 34px;
  `}
`;

export const HelpButton = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent url(${props => props.bg}) 0 0 no-repeat;
  ${props => (props.active ? 'background-position:0 -60px !important;' : '')};
  &:hover {
    background-position: 0 -30px;
  }
`;

export const ResponseMessage = styled.span`
  position: absolute;
  width: 100%;
  left: 0;
  top: 2px;
  color: ${props => props.status === 'n' && props.theme.green};
  color: ${props =>
    props.fixed && props.status === 'n' && props.theme.green_light};
  color: ${props => props.status === 'd' && props.theme.orange_dark};
  color: ${props =>
    props.fixed && props.status === 'd' && props.theme.orange_dark50};
  color: ${props => props.status === 'k' && props.theme.red};
  color: ${props =>
    props.fixed && props.status === 'k' && props.theme.red_light};
  font-family: ${props => props.theme.fonts.font};
  font-size: ${props => (props.fixed ? '12px' : '14px')};
  line-height: 16px;
  letter-spacing: 0.01em;
  white-space: wrap;
`;

export default {
  StyledGrid,
  Description,
  InputWrapper,
  UniqueCodeWrapper,
  GifEngineWrapper,
  Input,
  HelpButton,
  Title,
  ResponseMessage,
  LoadingBlock,
};
