import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spin = keyframes`
  0 {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const WrapperCircle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  text-align: center;
  border: 1px solid;
  border-color: ${props => (props.invert ? '#F4F9FA' : '#c6dfe7')};
  border-radius: 50%;
`;

const Spinner = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 50%;
  border-color: ${props =>
    props.invert
      ? 'transparent #80B0C8 #80B0C8'
      : 'transparent #004666 #004666'};
  animation: ${Spin} 690ms infinite linear;
`;

const Loader = ({ invert }) => {
  return (
    <WrapperCircle invert={invert}>
      <Spinner invert={invert} />
    </WrapperCircle>
  );
};

export default Loader;
